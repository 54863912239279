// TODO: close when clicking outside
// TODO: Select automatically the closest word to the written text
class bestoDropdown {
  constructor(dropdownDiv) {
    this.container = $(dropdownDiv);
    this.fakeInput = this.container.find('.bestoDropdownFakeInput');
    this.hiddenInput = this.container.find('.bestoDropdownHiddenInput');
    this.itemsDiv = this.container.find('.dropdown-items');
    this.options = this.container.find('.option');
    this.applyClickListeners();
    this.closeDropdown()
  }

  // Open or close when clicking on the input
  applyClickListeners(){
    this.fakeInput.click(() => {
      if (this.container.hasClass('bestoDropdown-open')){
        this.closeDropdown()
      } else {
        this.openDropdown()
      }
    })

  // Close it when clicking anywhere else
    document.addEventListener("click", (e) => {
      if (!$(e.target).hasClass('bestoDropdownFakeInput')){
        this.closeDropdown()
      }
    });

  // Update the fields when clicking on the options
    for (let i = 0; i < this.options.length; i++) {
      $(this.options[i]).click((event) => {
        this.fakeInput.text(event.target.textContent)
        this.hiddenInput.val(event.target.getAttribute('value'))
      })
    }
  }

  closeDropdown() {
    this.itemsDiv.hide()
    this.container.removeClass('bestoDropdown-open')
  }

  openDropdown() {
    this.itemsDiv.show()
    this.container.addClass('bestoDropdown-open')
  }
}

var dropdowns = $('.bestoDropdown')
for (let i = 0; i < dropdowns.length; i++) {
  new bestoDropdown(dropdowns[i]);
}
