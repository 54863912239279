import { autocomplete } from '../shared/search_box.js';
async function getProducts(val){
  if (!val){
    return gon.product_names
  } else {
    let res = []
    gon.product_names.forEach(function(product) {
      if (product.name.toLowerCase().includes(val.toLowerCase())) {
        res.push(product)
      }
    })
    return res
  }
}

async function getInitialProduct(){
  return gon.initial_product
}
document.addEventListener('turbolinks:load', function() {
  $( document ).ready(function() {
    (async () => {
      if (document.getElementById("productsInput")){
          await autocomplete(document.getElementById("productsInput"), getProducts, getInitialProduct);
      }
    })();
  });
});
