export async function autocomplete(inp, asyncOptionsFunction, asyncInitialValueFunction = null) {
  /*the autocomplete function takes a text field argument for the iput,
  a function to get the values:*/
  var currentFocus;

  async function setInitialValue() {
    let initialValue = await asyncInitialValueFunction()
    if (!initialValue) { return }
    console.log(initialValue)
    /*insert the value for the autocomplete text field:*/
    inp.value = initialValue.name;
    let currentInpId = inp.id;
    let elementIdInp = document.getElementById(currentInpId + "Id");
    elementIdInp.value = initialValue.value;
    /*close the list of autocompleted values,
    (or any other open lists of autocompleted values:*/
    closeAllLists();
  }

  if (asyncInitialValueFunction) {
    await setInitialValue()
  }

  /*execute a function when someone writes in the text field:*/
  inp.addEventListener("input", getOptions);

  inp.addEventListener("focus", getOptions);

  /*execute a function when user presses a key on the keyboard:*/
  inp.addEventListener("keydown", function(e) {
      var x = document.getElementById(this.id + "autocomplete-list");
      if (x) x = x.getElementsByTagName("div");
      if (e.keyCode == 40) {
        /*If the arrow DOWN key is pressed,
        increase the currentFocus variable:*/
        currentFocus++;
        /*and and make the current item more visible:*/
        addActive(x);
      } else if (e.keyCode == 38) { //up
        /*If the arrow UP key is pressed,
        decrease the currentFocus variable:*/
        currentFocus--;
        /*and and make the current item more visible:*/
        addActive(x);
      } else if (e.keyCode == 13) {
        /*If the ENTER key is pressed, prevent the form from being submitted,*/
        e.preventDefault();
        if (currentFocus > -1) {
          /*and simulate a click on the "active" item:*/
          if (x) x[currentFocus].click();
        }
      }
      let w = document.getElementById(this.id + "autocomplete-list");
  });

  async function getOptions(e) {
    var a, b, i, val = this.value;
    /*close any already open lists of autocompleted values*/
    closeAllLists();
    currentFocus = -1;
    /*create a DIV element that will contain the container. THis one is used for positioning only*/
    let w = document.createElement("DIV");
    w.setAttribute("id", this.id + "autocomplete-list-wrapper");
    w.setAttribute("class", "autocomplete-items-wrapper");
    /*create a DIV element that will contain the items (values):*/
    a = document.createElement("DIV");
    a.setAttribute("id", this.id + "autocomplete-list");
    a.setAttribute("class", "autocomplete-items");
    /*append the DIV element as a child of the autocomplete container:*/
    this.parentNode.appendChild(w);
    w.appendChild(a);
    // request the options from the back end
    var options = await asyncOptionsFunction(val);
    sortOptions(options, val, inp, a)
  }

  function sortOptions(options, val, inp, a){
    /*for each item in the array...*/
    for (let i = 0; i < options.length; i++) {
      /*create a DIV element for each matching element:*/
      let b = document.createElement("DIV");
      /*TODO: make the first instance of matching letters bold:*/
      b.innerHTML = options[i].name
      // b.innerHTML = "<strong>" + options[i].name.substr(0, val.length) + "</strong>";
      // b.innerHTML += options[i].name.substr(val.length);
      if (options[i].subtitle) {
          b.innerHTML +=  "<div class=result-subtitle>" + options[i].subtitle + "</div>"
      }
      /*insert a input field that will hold the current array item's value:*/
      let data_id = options[i].id || options[i].eid
      b.innerHTML += '<input type="hidden" value="' + options[i].name + '" data-id="' + data_id + '">';
      /*execute a function when someone clicks on the item value (DIV element):*/
      b.addEventListener("click", function(e) {
        /*insert the value for the autocomplete text field:*/
        inp.value = this.getElementsByTagName("input")[0].value;
        let currentInpId = inp.id;
        let elementIdInp = document.getElementById(currentInpId + "Id");
        elementIdInp.value = this.getElementsByTagName("input")[0].dataset.id;
        /*close the list of autocompleted values,
        (or any other open lists of autocompleted values:*/
        closeAllLists();
      });
      a.appendChild(b);
    }
  }

  function addActive(x) {
    /*a function to classify an item as "active":*/
    if (!x) return false;
    /*start by removing the "active" class on all items:*/
    removeActive(x);
    if (currentFocus >= x.length) currentFocus = 0;
    if (currentFocus < 0) currentFocus = (x.length - 1);
    /*add class "autocomplete-active":*/
    x[currentFocus].classList.add("autocomplete-active");
  }
  function removeActive(x) {
    /*a function to remove the "active" class from all autocomplete items:*/
    for (var i = 0; i < x.length; i++) {
      x[i].classList.remove("autocomplete-active");
    }
  }
  function closeAllLists(elmnt) {
    /*close all autocomplete lists in the document,
    except the one passed as an argument:*/
    var x = document.getElementsByClassName("autocomplete-items-wrapper");
    for (var i = 0; i < x.length; i++) {
      if (!elmnt || (elmnt != x[i] && elmnt != inp && elmnt.parentNode != x[i].parentNode)) {
        x[i].parentNode.removeChild(x[i]);
    }
  }
}
/*execute a function when someone clicks in the document:*/
document.addEventListener("click", function (e) {
    closeAllLists(e.target);
});
}
