function switchToMap(element) {
  console.log(element);
  element.classList.add("hidden");
  $('.switch-view.to-list').removeClass('hidden');
  $('#results-list').addClass('mobile-hidden');
  $('#results-map').removeClass('mobile-hidden');
}

function switchToList(element) {
  element.classList.add("hidden");
  $('.switch-view.to-map').removeClass('hidden');
  $('#results-list').removeClass('mobile-hidden');
  $('#results-map').addClass('mobile-hidden');
}

window.switchToMap = switchToMap
window.switchToList = switchToList
