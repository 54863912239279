import { autocomplete } from '../shared/search_box.js';
async function getAndHandlePlaces(val){
  let product_id = ''
  let product_input = document.getElementById("productsInputId")
  if (product_input) {
      product_id = product_input.value
  }
  return fetch("/api/v1/places?search=" + val + "&product_id=" + product_id)
    .then((data) => data.json())
    .then((html) => {
      var results = html["results"]
      results.forEach(function(result) {
      // result is a dictionary
        result["subtitle"] = result["address"]
      })
      return results
    });
}

async function getInitialPlace(){
  return gon.initial_place
}

document.addEventListener('turbolinks:load', function() {
  $( document ).ready(function() {
    (async () => {
      if (document.getElementById("placesInput")){
        await autocomplete(document.getElementById("placesInput"), getAndHandlePlaces, getInitialPlace);
      }
    })();
  });
});
